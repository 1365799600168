import { Injectable, ɵConsole } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, Subscription, merge, zip, concat} from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { map, tap, take} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './core/services/auth.service';
import Swal from 'sweetalert2';
import  * as firebase from 'firebase';
import * as moment from 'moment-timezone';
import * as FuzzySet from 'fuzzy.js';
import * as Fuse from 'fuse.js';
import { KeyValue } from '@angular/common';
import * as Cities from 'src/app/models/cities.json';
import * as States from 'src/app/models/states.json';

export interface Item { name: string; }

@Injectable({ providedIn: 'root' })

export class ApiService {
    public account:any = {};
    public accid:string = '';
    public urlId:string = '';
    public accnameid:string = '';
    public user:any  = {};
    stbase:string = environment.firebaseConfig.storageBucket;
    public globals:any = {}
    public dataSet = new BehaviorSubject<any>(false);
    public accountInfo = new BehaviorSubject<any>(false);
    public currDataset = {
      userData:{},
      accounts:{}
    }
    public fuse:any;
    public isUploading:boolean = false;
    public isSuccess:boolean = false;
    public loaded = false;
    public headerloaded = false;
    public currAcc:any = {};
    public currRace:any = {};
    public currEvent:any = {};
    public running = false;
    public timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    public originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
      return 0;
    }
    constructor(
        private adb: AngularFirestore,
        private storage: AngularFireStorage,
        private auth: AuthenticationService,
        private router: Router,
        public db: AngularFireDatabase,
        private route: ActivatedRoute,
        ){
          this.getGlobals();
          
        }

        dynamicSort(property) {
          var sortOrder = 1;
          if(property[0] === "-") {
              sortOrder = -1;
              property = property.substr(1);
          }
          return function (a,b) {
              /* next line works with strings and numbers, 
               * and you may want to customize it to your needs
               */
              var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
              return result * sortOrder;
          }
      }

  convertDate(date){
    return firebase.default.firestore.Timestamp.fromDate(date);
  }

  formatPhone(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  public toArray(obj){
    return Object.values(obj);
  }


  parseDateText(date:any){
    let d = date.toDate();
    let dt = moment(d).tz(this.timezone);
    return  dt.format('M/D/YY')
  }

  getAge(date:any){
    if (date){
      const today = new Date()
      let dt = moment(today).tz(this.timezone);
      return dt.diff(date, 'years');
    }
  }

  parseDateTimeText(date:any){
    let d = date.toDate();
    let dt = moment(d).tz(this.timezone);
    return  dt.format('M/D/YY h:mm A')
  }

  makeDate(addDays = 0, d = new Date()){
    return { year: d.getFullYear(), month: d.getMonth()+1, day: d.getDate() + addDays };
  }

  makeDateString(addDays = 0, d = new Date()){
    return { year: d.getFullYear(), month: d.getMonth()+1, day: d.getDate() + addDays };
  }


  makeDates(data, fields){
    Object.entries(fields).forEach((fld:any) => {
      console.log('fld', fld)
      if (fld[1].type === 'date'){
        data[fld[0]] = this.makeDate(fld[1].default);
      }else{
        data[fld[0]] = fld[1].default;
      }

      if (fld[1].type === 'datetime'){
        data[fld[0]] = this.makeDateTime(fld[1].default);
      }else{
        data[fld[0]] = fld[1].default;
      }
    })
  }

  makeDateTime(addDays = 0, d = new Date()){
    let dt = moment(d).tz(this.timezone).add(addDays, 'days');
    let formatted = dt.format(moment.HTML5_FMT.DATETIME_LOCAL);
    return formatted
  }

  clean(data, fields, out = true){

    function toObject(arr) {
      var rv = {};
      arr.forEach((val, i) => {
        rv[i] = val;
      })
      return rv;
    }

    
    let ret = Object.entries(data).map(ed => {
      let key:string = ed[0];
      let val:any = ed[1]
   
      if (key === 'id'){
        if (out){
          return null;
        }
      }

      if (key === 'protected'){
        if (out){
          return null;
        }
      }

      if (key in fields && fields[key].type === 'repeater'){
        if ('convertToMap' in fields[key] && fields[key].convertToMap){
          if (out){
            return [key, toObject(val)];
          }
          return [key, Object.values(val)];
        }
      }

      if (key in fields && fields[key].type === 'money'){
        if (out){
          return [key, Math.round(val*100)];
        }
        return [key, Math.round(val/100)];
      }

      if (key in fields && fields[key].type === 'date'){
        if (out){
          if (!val){
            return null;
          }
          return [key, this.convertDate(new Date(val.year, val.month - 1, val.day))];
        }
        return [key, this.makeDate(0, val.toDate())];
      }

      if (key in fields && fields[key].type === 'datetime'){
        if (out){
          return [key, this.convertDate(new Date(val))];
        }
        return [key, this.makeDateTime(0)];
      }

      return [key, val];

    }).filter((fi) => {
      //console.log(fi);
      return fi !== null;

    }).reduce((accum:any, v:any) => {
      accum[v[0]] = v[1];
      return accum;
    }, {});

    return ret;
  }


  getTimestamp(){
    return firebase.default.firestore.Timestamp.now();
  }

  getCountries(){
    /*
    let countries = Country.getAllCountries();
    let us:any = {};
    let fincountries = countries.filter((country, index, arr) => {
      if (country.isoCode === "US"){
        us = country;
      }
      return country.isoCode !== "US";
    });
    fincountries.unshift(us);
    return(fincountries);
    */
    return({US:"United States"});
  }
  
  makeInit(fields, maps){
    let gthis = this
    function parseval(val){
      if (val.type === 'datetime'){
        return gthis.makeDateTime(val.default);
      }

      if (val.type === 'date'){
        return gthis.makeDate(val.default);
      }

      return val.default
    }

    let finData = {};
    Object.entries(fields).forEach(([key, val]:any) => {
      if (val.type === 'map'){
        let map = {};
        Object.entries(maps[key]).forEach(([k, v]:any) => {
          map[k] = parseval(v);
        });

      }
      finData[key] = parseval(val);
     
    })
    return finData;
  }

  getStates(ccode = 'US'){
    return(States[ccode]);
  }

  matchCity(val, state){
    let set = FuzzySet();
    let map:any = {};
    Object.entries(Cities[state].cities).forEach(([name, zips]:any) =>{
      set.add(name);
    })
    let res = set.get(val);
    if (res in map){
      return map.res;
    }else{
      return '';
    }
  }

  getCities(state){
    return Object.entries(Cities[state].cities).map(([name, zips]:any) =>{
      return name;
    })
  }

  getZips(city, state){
    return Cities[state].cities[city];
  }

  matchState(val, ccode = 'US'){
    console.log('val', val)
    console.log('States', States)
    let set = FuzzySet();
    console.log('set', set)
    let map:any = {};
    console.log('States', States)
    Object.entries(States).forEach( ([code, name]:any) =>{
      set.add(name);
      map[name] = code;
    })
    
    let res = set.get(val);
    if (res in map){
      return map.res;
    }else{
      return '';
    }
  }

  getGlobals()
    {
      this.globals['states'] = States['default'];
      this.globals['countries'] = {"US":"United States"};
    }

    alert(title = '', msg = '', icon = 'success', redirect = '', showconfirm = false){
      return new Promise<any>(async (resolve, reject) => {
        let swal:any = {
          position: 'center',
          icon: icon,
          title: title,
          text: msg,
          showConfirmButton: true,
          willClose: () => {
            if (redirect){
              let redarr = redirect.split('?');
              let url = [redarr[0]];
              let params = {};
              if (redarr.length > 1){
                let paramsarr = redarr[1].split('=');
                let queryparams = {};
                paramsarr.forEach(p => {
                    queryparams[p] = p;
                })
                params['queryParams'] = queryparams;
              }
              this.router.navigate(url, params);
            }
            resolve(true);
          }
        };

        if (!showconfirm){
          swal.showConfirmButton = false;
          swal['timer'] = 1200;
        }

        Swal.fire(swal);
      });
    }

    public error(txt) {
      Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Erorr!',
          text: txt,
          showConfirmButton: true,
          //timer: 3500,
      });
  }

  public success(txt, redirect = '') {
      let btn = false;
      if (redirect) {
          btn = true;
      }
      Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Success!',
          text: txt,
          showConfirmButton: btn,
          timer: 1500,
          willClose: () => {
              if (redirect) {
                  this.router.navigate([redirect]);
              }
          },
      });
  }

      public waiting(title:string, msg:string) {
        let timerInterval;
        this.isUploading = true;
        this.isSuccess = false;
        Swal.close();
        Swal.fire({
            position: 'center',
            icon: 'info',
            title: title,
            text: msg,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        }).then((res) => {
            if (this.isSuccess) {
                this.success('Process Completed');
            } else {
                this.alert('Process Failed');
            }
        });

        timerInterval = setInterval(() => {
            if (!this.isUploading) {
                Swal.close();
            }
        }, 100);
    }

    hash(str) {
      let hash = 0;
      for (let i = 0, len = str.length; i < len; i++) {
          let chr = str.charCodeAt(i);
          hash = (hash << 5) - hash + chr;
          hash |= 0; // Convert to 32bit integer
      }
      return hash;
  }

    getAccountLs(){
      if (localStorage.getItem('account')){
        let acc = JSON.parse(localStorage.getItem('account'));
        if ('account' in acc){
        this.account = acc.account;
        this.accid = acc.account.id;
        return acc.id;
        }else{
          return false;
        }
      }
      return false;
    }

    setAccountLs(acc){
      if ('account' in acc){ 
        console.log('setacc', acc)
        localStorage.setItem('account', JSON.stringify(acc));
        this.account = acc
        this.accid = acc.account.id;
        console.log('setaccid', this.accid)
        console.log( )
      }
    }

    swapAccount(accid:string){
      console.log('asdasdsaas',this.currDataset.accounts[accid]);
      this.setAccountLs(this.currDataset.accounts[accid])
      this.dataSet.next(this.currDataset);
      //this.checkRoute(this.currDataset);
    }

    encodeUri(uri){
      return encodeURIComponent(uri);
    }

   async getData(acctId = ''){
       
        console.log('acctid', acctId)
        console.log('user', this.user)

        let acc:any = {};
        let foundAccount = false;
        this.currDataset = {
          userData:{},
          accounts:{}
        };
        if (acctId){
          const account = await this.adb.firestore.collection('accounts').where('acctId', '==', acctId).get();
          if (account.empty){
            this.router.navigate(['/']);
          }else{
            //console.log('account', account);
            let res = account.docs[0].data();
            res['id'] = account.docs[0].id;
            this.account = res
            this.accid = res.id;
            this.urlId = acctId;
            this.accountInfo.next(res)
            this.headerloaded = true;
          }
          this.user = this.auth.currentUser();
          
        if (this.user !== null){ 
        
        this.adb.doc('users/' + this.user.uid).valueChanges({ idField: 'id' }).subscribe((user:any) => {
          //console.log('user', user.exists);
          this.currDataset.userData = user;
          this.adb.collection('accounts', ref => ref.where('protected.managerId', '==', this.user.uid).orderBy('protected.created','asc')).valueChanges({ idField: 'id' }).subscribe(async (account:any) => {
            
            this.currDataset.accounts = {};
            
              account.forEach(ac => {
                let acentry = {
                  type:'root',
                  accountId:ac.id
                };
                if ('accId' in ac && ac['accId'] !== ''){
                  this.currDataset.accounts[ac['accId']] = acentry;
                }
                
              })
            this.adb.collectionGroup('userData', ref => ref.where('protected.userId', '==', this.user.uid)).valueChanges({ idField: 'id' }).subscribe(async (userData:any) => {
                let accountIds = [];
                userData.forEach(ud => {
                  if ('protected' in ud && 'acctId' in ud['protected'] && ud['protected']['acctId'] !== '' && !accountIds.includes(ud['protected']['acctId'])  && ud['protected']['acctId'] !== this.accid) {
                    accountIds.push(ud['protected']['acctId']);
                  }
                });

                if (accountIds.length){
                  await Promise.all(
                    accountIds.map(async (ac:any) => {
                      //console.log(ac)
                          const acdata = await this.adb.firestore.collection('accounts').doc(ac).get();
                          if (acdata.exists){
                            let acentry = {
                              type:'nonroot',
                              accountId:ac.id
                            };
                            this.currDataset.accounts[acdata['acctId']] = acentry;
                          }
                      })
                  )
                }

              this.dataSet.next(this.currDataset);
              this.loaded = true;
         

            });
          });
        });
      

        }else{
          this.loaded = true;
          this.dataSet.next(this.currDataset);
        }
      }
    }
    

    parseData(data, fields, maps = {}){

    }


    addLogo(data, type, evid = ''): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
          try{
            let add = '/';
            if (evid){
              add = '/' + evid + '/'
            }

            const listRef = this.storage.ref('/logos/' + this.accid + add + 'logo.' + type);
            const upload = await listRef.putString(data, 'data_url');
            resolve(true);
          }catch(err){
            console.log(err)
            reject(err);
          }
        });
      }

      getLogo(type, evid = ''): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
          try{
            let add = '/';
            if (evid){
              add = '/' + evid + '/'
            }
            const listRef = this.storage.ref('/logos/' + this.accid + add + 'logo.' + type);
            const url = await listRef.getDownloadURL().toPromise();
            resolve(url);
          }catch(err){
            console.log(err)
            resolve("");
          }
        });
      }


  async checkRoute(data){
      if (!Object.values(data.accounts).length){
        //create account
        //this.router.navigate(['/accounts/new']);
      }

      console.log(this.accid);

      const events = await this.adb.firestore.collection('accounts').doc(this.accid).collection('events').get();
      if (events.empty){
        //this.router.navigate(['/events/new']);
      }
      
    }

    isValid(val, type, convert = false) {
      if (!val) { 
        return false;
      }
  
      if (Array.isArray(val)){
        if (!val.length){
          return false;
        }
      }
  
      if ( (typeof val === 'function') || (typeof val === 'object') ){
        if (!Object.keys(val).length){
          return false;
        }
      }

      if ( type === 'int' || type === 'float' || type === 'money'){
        if (val > 0){
          return true;
        }else{
          return false;
        }
      }

      if ( type === 'email'){
        var email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;;
        if (!val.match(email)){
          return false;
        }
      }

      if ( type === 'percent'){
        if (val > 0 && val < 100){
          return true;
        }else{
          return false;
        }
      }

      if ( type === 'phone' ){
        var phoneno = /^\d{10}$/;
         if(!val.match(phoneno)){
          return false;
         }
      }

      return true;
    }

    validate(data, fields, mapname= '', count = -1, maps = {}){
      let invalid = [];
      console.log(data);
      Object.entries(data).forEach(ed => {
        let key = ed[0];
        let val = ed[1];

        if (key in maps){
          const subdata = val[key];
          console.log(subdata);
          if (fields[key].type === 'repeater'){
            subdata.forEach((dt, i) => {
              invalid.push(this.validate(dt, maps[key], key, i))
            })
          }else{
            invalid.push(this.validate(subdata, maps[key], key))
          }
        }

        if (key in fields && fields[key].required){
          if (!this.isValid(val, fields[key].type)){
            if (mapname){
              key += '.' + mapname
            }

            if (count > -1){
              key += '.' + count
            }
            invalid.push(key);
          }
        }
      })
      return invalid;
    }


    cleanData(data){
      let cp = {...data};
      Object.entries(cp).forEach(ent => {
        if (ent[1] instanceof firebase.default.firestore.Timestamp){
          let ts:any = ent[1];
          cp[ent[0]] = this.makeDate(0, ts.toDate());
        }
      })
      delete cp.id;
      delete cp.protected;
      return cp;
    }


    isNumber(n) { 
      return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    }

    updateAccount(accdata, userdata, logoData): Promise<any>{
      return new Promise<any>(async (resolve, reject) => {
        try{
          const acc = await this.adb.collection('accounts').doc(this.accid).update(accdata);
          const user = await this.adb.collection('users').doc(this.user.uid).update(userdata);
          if (accdata.logo_type && logoData.new_logo){
            const upload = await this.addLogo(logoData.url, accdata.logo_type, this.accid)
          }
          this.alert('Account Updated!', '', 'success',  '/' + this.urlId + '/dash', false)
          resolve(acc);
        }catch(err){
          console.log(err);
          this.alert('Error', 'There was an issue saving your data. Please try again.', 'warning', '', false)
          resolve(err);
        }
      })
    }

    addAccount(accdata, userdata, logoData): Promise<any>{
      return new Promise<any>(async (resolve, reject) => {
        try{
          accdata['uid'] = this.user.uid;
          const acc = await this.adb.collection('accounts').add(accdata);
          const user = await this.adb.collection('users').doc(this.user.uid).update(userdata);
          if (accdata.logo_type && logoData.new_logo){
            const upload = await this.addLogo(logoData.url, accdata.logo_type, acc.id)
          }
          this.alert('Account Added!', '', 'success',  '/' + this.urlId + '/dash', false)
          resolve(acc);
        }catch(err){
          console.log(err);
          this.alert('Error', 'There was an issue saving your data. Please try again.', 'warning', '', false)
          resolve(err);
        }
      })
    }

    addEvent(eventdata, logoData): Promise<any>{
      return new Promise<any>(async (resolve, reject) => {
        try{
          const acc = await this.adb.collection('accounts').doc(this.accid).collection('events').add(eventdata);
          if (eventdata.logo_type && logoData.new_logo){
            const upload = await this.addLogo(logoData.url, eventdata.logo_type, acc.id)
          }
          this.alert('Event Added!', '', 'success', '/' + this.urlId + '/dash', false)
          resolve(acc);
        }catch(err){
          console.log(err);
          this.alert('Error', 'There was an issue saving your data. Please try again.', 'warning', '', false)
          resolve(err);
        }
      })
    }

    updateEvent(eventdata, evid, logoData): Promise<any>{
      return new Promise<any>(async (resolve, reject) => {
        try{
          const acc = await this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).update(eventdata);
          if (eventdata.logo_type && logoData.new_logo){
            const upload = await this.addLogo(logoData.url, eventdata.logo_type, evid)
          }
          this.alert('Event Updated!', '', 'success',  '/' + this.urlId + '/dash', false)
          resolve(acc);
        }catch(err){
          console.log(err);
          this.alert('Error', 'There was an issue saving your data. Please try again.', 'warning', '', false)
          resolve(err);
        }
      })
    }


    deleteEvent(evid): Promise<any>{
      return new Promise<any>(async (resolve, reject) => {
        try{
          const acc = await this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).delete();
          this.alert('Event Deleted!', '', 'success',  '/' + this.urlId + '/dash', false)
          resolve(acc);
        }catch(err){
          console.log(err);
          this.alert('Error', 'There was an issue deleting your data. Please try again.', 'warning', '', false)
          resolve(err);
        }
      })
    }

    

    updateUser(entrydata): Promise<any>{
      return new Promise<any>(async (resolve, reject) => {
        try{
          const acc = await this.adb.collection('users').doc(this.user.uid).update(entrydata);
          resolve(acc);
        }catch(err){
          console.log(err);
          resolve(err);
        }
      })
    }

    getdbs(dbid):Observable<[][]>{
        return this.adb.collection(dbid).snapshotChanges()
        .pipe(
            map(actions => {
              return actions.map(a => {
                const data = a.payload.doc.data() as [];
                const id = a.payload.doc.id;
                return { id, ...data };
              });
            })
        );
    } 

    isEmpty(obj) {
      for(var prop in obj) {
        if(obj.hasOwnProperty(prop)) {
          return false;
        }
      }
    
      return JSON.stringify(obj) === JSON.stringify({});
    }

    getAccount(): Promise<any> {
      return new Promise<any>(async (resolve, reject) => {
        try{
          const acct = await this.adb.firestore.collection('accounts').where('protected.managerId', '==', this.user.uid).get();
          if (acct.empty){
           //const users = await this.adb.firestore.collectionGroup('userData').where('userId', '==', user.uid);
            //throw {data:'No accounts Found', message:'No accounts were found.'};
          }
          acct.forEach((doc) => {
            console.log(doc.data());
          })
         
          resolve(acct);
        }catch(err){
          this.alert('Account Error!', err.message, 'error', '/account/login')
          reject(false);
        }
      });
    }

    getAccountbyId(id): Promise<any> {
      return new Promise<any>(async (resolve, reject) => {
      try{
        const acct = await this.adb.firestore.collection('accounts').doc(id).get();
        this.accid = id;
        resolve(acct.data())
      }catch(err){
        this.router.navigate(['/']);
      }
      });
    }

    getAccountbyUrl(accid): Promise<any> {
      return new Promise<any>(async (resolve, reject) => {
      try{
        const acct = await this.adb.firestore.collection('accounts').where('acctId', '==', accid).get();
        console.log(acct)
        if (acct.empty){
          this.router.navigate(['/']);
        }else{
          const accid = acct.docs[0].id;
          let data = acct.docs[0].data();
          data['id'] = accid;
          resolve(data);
        }
        
      }catch(err){
        console.log(err);
        this.router.navigate(['/']);
      }
      });
    } 

    async getRound(){
      const ref = this.storage.ref('Ih06JdgvOUmXdqEB8r0T/r1.json');
      let res = await ref.getDownloadURL().toPromise()
      console.log(res)
    }

    getEventsPromise(accid = ''):Promise<any>{
      if (!accid){
        accid = this.accid;
      }
      return new Promise<any>(async (resolve, reject) => {
        try{
          const acct = await this.adb.firestore.collection('accounts').doc(accid).collection('events').where('end_date', '>=', this.getTimestamp()).orderBy('end_date', 'desc').limit(3).get();
          if (acct.empty){
            resolve([]);
          }else{
            let data = [];

            acct.forEach((doc) => {
              let d = doc.data();
              d['id'] = doc.id;
              data.push(d);
            });
            resolve(data);
          }
          
        }catch(err){
          console.log(err);
          reject(err);
        }
        });
 
    }



    getUser():Observable<any>{
      return this.adb.collection('users').doc(localStorage.getItem('uid')).valueChanges({ idField: 'id' })
    }

    getAccounts():Observable<any>{
      return this.adb.collection('accounts', ref => ref.where('protected.managerId', '==', this.user.uid)).valueChanges({ idField: 'id' });
    } 

    getUserAccounts(userid):Observable<any>{
      return this.adb.collectionGroup('userData', ref => ref.where('userId', '==', userid)).valueChanges({ idField: 'id' })
    } 

    getEvents(accid = ''):Observable<any>{
      if (!accid){
        accid = this.accid;
      }
      //console.log('asda', this.accid)
      return this.adb.collection('accounts').doc(this.accid).collection('events', ref => ref.orderBy('start_date', 'desc')).valueChanges({ idField: 'id' });
    }

    getEvent(evid):Observable<any>{
      return this.adb.doc('accounts/' + this.accid + '/events/' + evid).valueChanges({ idField: 'id' });
    }

    getRaces(evid):Observable<any>{
      return this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races', ref => ref.orderBy('start_time', 'desc')).valueChanges({ idField: 'id' });
    } 

    getLiveRaces():Observable<any>{
      return this.adb.collectionGroup('races', ref => ref.where('currRound', '>', 0).where('accId', '==', this.accid)).valueChanges({ idField: 'id' });
    } 

    getRace(evid, raceid, accid = this.accid):Observable<any>{
      return this.adb.collection('accounts').doc(accid).collection('events').doc(evid).collection('races').doc(raceid).valueChanges({ idField: 'id' })
    } 

    getEntry(evid, raceid, entryid):Observable<any>{
      return this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).collection('entries').doc(entryid).valueChanges({ idField: 'id' })
    }
    
    getEntriesPromise(evid, raceid){
      return new Promise<any>(async (resolve, reject) => {
        const ss:any = await this.adb.firestore.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).collection('entries').get()
        let dataobj = {};
        ss.forEach(doc => {
          dataobj[doc.id] = doc.data();
        });
        resolve(dataobj);
      })
    } 

    getUnDrawn(evid, raceid){
      return new Promise<any>(async (resolve, reject) => {
        const ss:any = await this.adb.firestore.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).collection('entries').get()
        let noDraw = [];
        ss.forEach(doc => {
          const ent = doc.data();
          Object.entries(ent.horses).forEach(([key, val]:any) => {
            let re = {
              city:ent.city,
              draw:val.draw,
              entryId:doc.id,
              horse:val.name,
              id:key,
              lastChange:0,
              rider:ent.first_name + ' ' + ent.last_name,
              state:ent.state,
              times:[]
            }
            if (val.draw === 0){
              noDraw.push(re);
            }
          })
        });
        resolve(noDraw);
      })
    } 

    getEntries(evid, raceid):Observable<any>{
      console.log(evid)
      console.log(raceid)
      
      return this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).collection('entries').valueChanges({ idField: 'id' })
    } 

    getEntriesLimit(evid:string, raceid:string, limit:number = 5, orderBy:string = 'protected.added', order:any = 'desc', lastdoc:string = ''):Observable<any>{
    
      if (lastdoc){
        return this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).collection('entries', ref =>
        ref.limit(limit).startAfter(lastdoc) ).valueChanges({ idField: 'id' })
      }else{
        //return this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).collection('entries', ref =>
       // ref.orderBy(orderBy, order).limit(limit) ).valueChanges({ idField: 'id' })
       return this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).collection('entries', ref =>
       ref.limit(limit) ).valueChanges({ idField: 'id' })
      }
      
    } 

    getEntriesSearch(evid:string, raceid:string, searchTerm:string, limit:number = 50):Observable<any>{
      const q1 = this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).collection('entries', ref =>
      ref.where('first_name', '>=', searchTerm).where('first_name', '<=', searchTerm + '\uf8ff').orderBy('first_name', 'desc').limit(limit) ).valueChanges({ idField: 'id' })
      const q2 = this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).collection('entries', ref =>
      ref.where('last_name', '>=', searchTerm).where('last_name', '<=', searchTerm + '\uf8ff').orderBy('last_name', 'desc').limit(limit) ).valueChanges({ idField: 'id' })
      return zip(q1, q2).pipe(map(x => x[0].concat(x[1])));
    }




    getUsersByRace(raceid):Observable<any>{
      console.log(raceid)
      return combineLatest([
        this.adb.collection('accounts').doc(this.accid).collection('userData', ref => ref.where('protected.permissions', '==', true)).valueChanges({ idField: 'id' }), 
        this.adb.collection('accounts').doc(this.accid).collection('userData', ref => ref.where('races', 'array-contains', raceid)).valueChanges({ idField: 'id' })
      ])
        .pipe(
          //tap( x => console.log('HTTP response:', x)),
          map(
            x => x[0]['id'] != x[1]['id'] ? x[0].concat(x[1]) : x[0]
            )
          )
    } 


    addRace(racedata, evid): Promise<any>{
      return new Promise<any>(async (resolve, reject) => {
        try{
          console.log(racedata)
          console.log(evid)
        //add round
        if (!('currRound' in racedata)){
          racedata['currRound'] = 0;
        }
          racedata['eventId'] = evid;
          racedata['accId'] = this.accid;
        const race = await this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').add(racedata)
        this.alert('Race Saved!', '', 'success', '', false)  
        resolve(race);
        }catch(err){
          console.log(err);
          this.alert('Error', 'There was an issue saving the race. Please try again.', 'warning', '', false)
          resolve(err);
        }
      })
    }

    updateRace(racedata, raceid, evid): Promise<any>{
      return new Promise<any>(async (resolve, reject) => {
        try{
          //racedata['eventId'] = evid;
          //racedata['accId'] = this.accid;
          const acc = await this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).update(racedata);
          this.alert('Race Updated!', '', 'success', '', false)
          resolve(acc);
        }catch(err){
          console.log(err);
          this.alert('Error', 'There was an issue saving your data. Please try again.', 'warning', '', false)
          resolve(err);
        }
      })
    }

    addEmail(emdata): Promise<any>{
      return new Promise<any>(async (resolve, reject) => {
        try{
        const race = await this.adb.collection('emails').add(emdata)
          resolve(race);
        }catch(err){
          reject(err);
        }
      })
    }

    deleteRace(raceid): Promise<any>{
      return new Promise<any>(async (resolve, reject) => {
        try{
        const race = await this.adb.collection('accounts').doc(this.accid).collection('races').doc(raceid).delete();
          resolve(race);
        }catch(err){
          reject(err);
        }
      })
    }

  addEntry(entrydata, raceid, evid): Promise<any>{
    return new Promise<any>(async (resolve, reject) => {
      try{
        //adding manual errors.
        if (!('first_name' in entrydata) || !entrydata.first_name){
          throw new Error('First name is required.');
        }
        if (!('last_name' in entrydata) || !entrydata.last_name){
          throw new Error('Last name is required.');
        }
        if (!('city' in entrydata) || !entrydata.city){
          throw new Error('City is required.');
        }
        if (!('state' in entrydata) || !entrydata.state){
          throw new Error('State is required.');
        }
      const race = await this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).collection('entries').add(entrydata)
        resolve(race);
      }catch(err){
        reject(err);
      }
    })
  }

  batchAddEntries(entries, raceid, evid){
    return new Promise<any>(async (resolve, reject) => {
      try{
        var db = this.adb.firestore;
        var batch = db.batch();
        let fingroup = [entries];
        if (entries.length >= 500){
          const middleIndex = Math.ceil(entries.length / 2);
          fingroup = [entries.splice(0, middleIndex), entries.splice(-middleIndex)]
        }
        console.log(fingroup);
        console.log(raceid);
        console.log(evid);
        await Promise.all(fingroup.map(async (grp, i) => {
          console.log('grp' + i, grp);
          console.log('this.accid',this.accid);
          console.log('evid',evid);
          console.log('raceid',raceid);
          grp.forEach(ent => {
            var docRef = db.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).collection('entries').doc(ent.id); 

            delete ent.id;
            console.log(ent)
            batch.set(docRef, ent);
          })
          let res = await batch.commit();
          console.log('batch', res);
        }))
        resolve(true)
      }catch(err){
        console.log('batcherr', err);
        resolve(false)
      }
    })
  }

  batchUpdateDraw(entries, raceid, evid){
    return new Promise<any>(async (resolve, reject) => {
      try{
      let keys = {};
      //console.log(entries);
      entries.forEach(async (ent) => {
         keys[ent.key + '/draw'] = ent.draw;
      })
      //console.log(keys);
      const itemRef = this.db.object('accounts/' + this.accid + '/events/' + evid + '/races/' + raceid + '/entries');
      await itemRef.update(keys);
      resolve(true)
    }catch(err){
      console.log(err);
      resolve(false)
    }
    })
  }

  batchUpdateFinalDraw(entries, raceid, evid){
    return new Promise<any>(async (resolve, reject) => {
      try{
        let keys = {};
        //console.log(entries);
        entries.forEach(async (ent) => {
           keys[ent.key + '/finaldraw'] = ent.finaldraw;
        })

        const itemRef = this.db.object('accounts/' + this.accid + '/events/' + evid + '/races/' + raceid + '/entries');
      await itemRef.update(keys);
      resolve(true)
    }catch(err){
      console.log(err);
      resolve(false)
    }
    })
  }

  batchDeleteEntries(entries, raceid, evid){
    return new Promise<any>(async (resolve, reject) => {
      try{
      var db = this.adb.firestore;
      var batch = db.batch();
      let fingroup = [entries];
      if (entries.length >= 500){
        const middleIndex = Math.ceil(entries.length / 2);
        fingroup = [entries.splice(0, middleIndex), entries.splice(-middleIndex)]
      }
        await Promise.all(fingroup.map(async (grp) => {
          grp.forEach(ent => {
            var docRef = db.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).collection('entries').doc(ent.entryid); //automatically generate unique id
            batch.delete(docRef);
          })
          await batch.commit();
          resolve(true)
        }));
      }catch(err){
        console.log(err);
      }
    })
  }


  updateEntry(entrydata, raceid, evid, entryid): Promise<any>{
    return new Promise<any>(async (resolve, reject) => {
      try{
         //adding manual errors.
         if (!('first_name' in entrydata) || !entrydata.first_name){
          throw new Error('First name is required.');
        }
        if (!('last_name' in entrydata) || !entrydata.last_name){
          throw new Error('Last name is required.');
        }
        if (!('city' in entrydata) || !entrydata.city){
          throw new Error('City is required.');
        }
        if (!('state' in entrydata) || !entrydata.state){
          throw new Error('State is required.');
        }
        const acc = await this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).collection('entries').doc(entryid).update(entrydata);

        this.alert('Entry Updated!', '', 'success', '', false)
        resolve(acc);
      }catch(err){
        console.log(err);
        this.alert('Error', 'There was an issue saving your data. Please try again.', 'warning', '', false)
        resolve(err);
      }
    })
  }

  deleteEntry(raceid, evid, entryid): Promise<any>{
    return new Promise<any>(async (resolve, reject) => {
      try{
      const race = await this.adb.collection('accounts').doc(this.accid).collection('events').doc(evid).collection('races').doc(raceid).collection('entries').doc(entryid).delete();
        resolve(race);
      }catch(err){
        reject(err);
      }
    })
  }


    getdb(dbid, gid):Observable<any>{
      return this.adb.collection(dbid).doc(gid).valueChanges()
    } 




    getCategory(dbid): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
          const category = await this.adb.collection('categories').doc(dbid).get();
          category.subscribe((category: any) => {
            resolve(category.data());
          }, error => {
            reject(error);
          });
        });
      }

      getPlayer(pid): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
          const player = await this.adb.collection('players').doc(pid).get();
          player.subscribe((player: any) => {
            //console.log(JSON.stringify(player.data()))
            resolve(player.data());

          }, error => {
            reject(error);
          });
        });
      }


      getModel(dbid): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
          const category = await this.adb.collection('models').doc(dbid).get();
          category.subscribe((category: any) => {
            resolve(category.data());
          }, error => {
            reject(error);
          });
        });
      }




      public fireAlert(success:boolean, title:string, txt:string = ''){
        return Swal.fire({
          position: 'center',
          icon: success ? 'success' : 'warning',
          title: title,
          text: txt,
          showConfirmButton: false,
          timer: 1000
        })
      }

      public ok(txt, title,  redirect = '') {
        let btn = false;
        if (redirect) {
            btn = true;
        }
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: title,
            text: txt,
            showConfirmButton: btn,
            timer: 1500,
            willClose: () => {
                if (redirect) {
                    this.router.navigate([redirect])
                }
            },
        });
    }

      async toDataURL(url){
        return new Promise<any>(async (resolve, reject) => {
        fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
            }
          )
        })
      }

      public parseTS(time:any){
        var date = new Date();

        if (typeof time === 'object' && 'seconds' in time){
          date = new Date(time.seconds);
        }else{
          if (time > 0){
            date = new Date(time);
          }
        }
        
        // Will display time in 10:30:23 format
        return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() + ' @ ' + this.formatAMPM(date);
      }
    
      formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      }

      makeAnimLink(id, nam){
        return this.stbase + 'characters%2F' + id + '%2Fanimation%2F' + nam + '?alt=media';
      }

      makeBossLink(id, nam){
        return this.stbase + 'stages%2F' + id + '%2Fboss%2Fanimation%2F' + nam + '?alt=media';
      }


}
