import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';

const routes: Routes = [
  //{ path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
 // { path: 'mega22', loadChildren: () => import('./live/live.module').then(m => m.LiveModule) },
  // tslint:disable-next-line: max-line-length
  { path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  //{ path: ':accId/dash', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  //{ path: ':accId/dash', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
 // { path: ':username', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
