<ng-container *ngIf="api.loaded">
<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-light.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="19">
          </span>
        </a>
      </div>

      

      <!-- App Search-->
      <form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="Search..">
          <span class="bx bx-search-alt"></span>
        </div>
      </form>

    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block d-lg-none me-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" ngbDropdownToggle>
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu>

          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Global Search">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

    
      <div  class="dropdown d-none d-lg-inline-block ml-1 mt-2" ngbDropdown>
        <button class="btn dropdown-toggle" style="font-size:18px;font-weight:bold;" type="button" id="dropdownMenuButton"
              ngbDropdownToggle>
              {{dataSet.accounts[api.accid].account.name}} <i class="mdi mdi-chevron-down"></i>
            </button>
            <div class="dropdown-menu" ngbDropdownMenu>
                <ng-container *ngFor="let asd of dataSet.accounts | keyvalue;">
                    <a *ngIf="api.accid !== asd.key" class="dropdown-item" (click)="api.swapAccount(asd.key)">{{asd.value.account.name}}</a>
                </ng-container>
                <a class="dropdown-item" routerLink="/accounts/new">Add New Account</a>
            </div>

      </div>
      <div class="dropdown d-none d-lg-inline-block me-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill">3</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Notifications </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">View All</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px;">
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">Your Race has begun</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">Click here to see live results</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>
                      3 minutes ago</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">New User Added</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">New User</p>
                    <p class="mb-0"><i
                        class="mdi mdi-clock-outline"></i>4 minutes ago</p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-light btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-down-circle me-1"></i> Load More
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img class="rounded-circle header-profile-user" [src]="'https://ui-avatars.com/api/?bold=true&name=' + dataSet.userData?.first_name + '+' + dataSet.userData?.last_name" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{dataSet.userData?.first_name}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            Profile</a>
          <a class="dropdown-item d-block" href="javascript: void(0);"><span
              class="badge bg-success float-end">11</span><i
              class="bx bx-wrench font-size-16 align-middle me-1"></i>Settings</a>
          <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-lock-open font-size-16 align-middle me-1"></i>
           Lock Screen</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
           Logout</a>
        </div>
      </div>
      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars"></i>
      </button>

    </div>
  </div>
</header>
<div  *ngIf="(dataSet.accounts | json) !== '{}'" class="topnav">
  <div class="container-fluid" style="text-align:center;">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul  class="navbar-nav">
          <li style="float:right" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)"
            href="javascript: void(0);" id="topnav-components" role="button">
            <i class="bx bx-layout mr-1"></i>
            Events
            <div class="arrow-down"></div>
            </a>
            <div class="dropdown-menu row" aria-labelledby="topnav-dashboard">
              <a class="col dropdown-item side-nav-link-ref"  href="javascript: void(0);"
              routerLink="/accounts/grid" routerLinkActive="active">View All</a>
              <a class="col dropdown-item side-nav-link-ref"  href="javascript: void(0);"
              routerLink="/accounts/create" routerLinkActive="active">Add New</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a routerLink="/users/grid"  class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" id="topnav-layout" role="button">
              <i class="bx bx-user me-2"></i>Users
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
</ng-container>